/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import styled from 'styled-components';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import {
  SectionTitle,
} from '../Global';
import MediaBreakDown from '../../styles/MediaBreakDown';


const SolutionSectionWrapper = styled.div`
  margin: 50px 0 0 0;

  ${SectionTitle} {
    text-align: center;
  }

  ${MediaBreakDown.lg`
  `}
`;
SolutionSectionWrapper.displayName = 'SolutionSectionWrapper';


/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/

export default SolutionSectionWrapper;
