/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
import Card from '../Card';
import { Grid, GridItem } from '../Grid';
// Styles
import SolutionSectionWrapper from './SolutionSection.style';
import {
  SectionTitle,
} from '../Global';


const SolutionSection = (props) => {
  const {
    title,
    items,
  } = props;


  const gridComponent = (
    <Grid gridGap="2rem 2rem" minCol="280px" maxCol="320px">
      {items.map(templateItem => (
        <GridItem key={_get(templateItem, 'slug', '')}>
          <Card
            link={`/templates/${_get(templateItem, 'slug', '')}/`}
            title={_get(templateItem, 'name', '')}
            description={_get(templateItem, 'tagline', '')}
            image={_get(templateItem, 'images.0', null)}
          />
        </GridItem>
      ))}
    </Grid>
  );


  return (
    <SolutionSectionWrapper>
      <SectionTitle mb="40px">{title}</SectionTitle>
      <div className="grid-wrapper">
        {gridComponent}
      </div>
    </SolutionSectionWrapper>
  );
};


SolutionSection.defaultProps = {
  title: 'Start from a template',
  items: [],
};
SolutionSection.propTypes = {
  // Optional props:
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      tagline: PropTypes.string.isRequired,
      solution: PropTypes.shape({
        team: PropTypes.string,
      }),
      images: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
          gatsbyImageData: PropTypes.shape({
          }),
        }),
      ),
    })
  ),
};


/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default SolutionSection;
