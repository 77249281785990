/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import _get from 'lodash/get';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '../components/Container';
import SignUpModal from '../components/SignUpModal';
import SolutionSection from '../components/Solution/SolutionSection';
import Testimonials from '../components/Testimonials';
// Style
import {
  BodyTextBig,
  DotsDivider,
  FlexWrap,
  GlobalHero,
  GraySection,
  HeroHeading,
  HeroPreHeading,
  ImageWrap,
  SectionLabel,
  SectionTitle,
  TextWrap,
  WhiteSection,
} from '../components/Global';
import {
  TestimonialSection,
} from '../components/Solution/index.style';
import SimplifyTeamWork from '../components/SimplifyTeamWork';


const IMAGE_STYLE = {
  FULL_SCREEN: 'Full screen',
  LEFT_IMAGE: 'Left image',
  RIGHT_IMAGE: 'Right image',
};


const FeatureItem = (props) => {
  const {
    feature,
    showWhiteBackground,
  } = props;


  let sectionComponent;
  let parentProps;

  switch (feature.style) {
    case IMAGE_STYLE.FULL_SCREEN:
      parentProps = {
        className: 'centered-text',
      };
      sectionComponent = (
        <Container>
          <SectionLabel>{feature.tagline}</SectionLabel>
          <SectionTitle mb="15px">
            {feature.title}
          </SectionTitle>
          <BodyTextBig maxWidth="615px" mb="34px">
            {feature.description}
          </BodyTextBig>
          <BodyTextBig mb="34px">
            <a
              href={feature.linkUrl}
              target="_blank"
              rel="noreferrer"
            >
              {feature.linkText}
            </a>
          </BodyTextBig>
          <ImageWrap width="100%">
            <GatsbyImage image={getImage(feature.image)} alt={feature.title} />
          </ImageWrap>
        </Container>
      );
      break;
    case IMAGE_STYLE.LEFT_IMAGE:
      parentProps = {
        textAlign: 'left',
      };
      sectionComponent = (
        <Container>
          <FlexWrap alignItems="center" direction="column-reverse">
            <ImageWrap width="55%">
              <GatsbyImage image={getImage(feature.image)} alt={feature.title} />
            </ImageWrap>
            <TextWrap width="40%">
              <SectionLabel>{feature.tagline}</SectionLabel>
              <SectionTitle mb="30px">
                {feature.title}
              </SectionTitle>
              <BodyTextBig mb="34px">
                {feature.description}
              </BodyTextBig>
              <BodyTextBig mb="34px">
                <a
                  href={feature.linkUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {feature.linkText}
                </a>
              </BodyTextBig>
            </TextWrap>
          </FlexWrap>
        </Container>
      );
      break;
    case IMAGE_STYLE.RIGHT_IMAGE:
      parentProps = {
        textAlign: 'left',
      };
      sectionComponent = (
        <Container>
          <FlexWrap alignItems="center">
            <TextWrap width="40%">
              <SectionLabel>{feature.tagline}</SectionLabel>
              <SectionTitle mb="30px">
                {feature.title}
              </SectionTitle>
              <BodyTextBig mb="34px">
                {feature.description}
              </BodyTextBig>
              <BodyTextBig mb="34px">
                <a
                  href={feature.linkUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {feature.linkText}
                </a>
              </BodyTextBig>
            </TextWrap>
            <ImageWrap width="55%">
              <GatsbyImage image={getImage(feature.image)} alt={feature.title} />
            </ImageWrap>
          </FlexWrap>
        </Container>
      );
      break;
    default:
      break;
  }

  if (showWhiteBackground) {
    sectionComponent = (
      <WhiteSection {...parentProps}>
        {sectionComponent}
      </WhiteSection>
    );
  }
  else {
    sectionComponent = (
      <GraySection {...parentProps}>
        {sectionComponent}
      </GraySection>
    );
  }
  return sectionComponent;
};


const SolutionPage = ({ location, data }) => {
  const team = _get(data, 'contentfulSolutionPage.team', '');
  const heroTitle = _get(data, 'contentfulSolutionPage.heroTitle', '');
  const heroText = _get(data, 'contentfulSolutionPage.heroText.heroText', '');
  const heroImage = _get(data, 'contentfulSolutionPage.heroImage', {});
  const features = _get(data, 'contentfulSolutionPage.features', []) || [];
  const reviews = _get(data, 'contentfulSolutionPage.reviews', []) || [];
  const templateItems = _get(data, 'allContentfulTemplate.nodes', []);

  const featureItems = features.map(featureItem => ({
    ...featureItem,
    description: _get(featureItem, 'description.description', ''),
  }));
  const reviewItems = reviews.map(reviewItem => ({
    node: {
      ...reviewItem,
    },
  }));


  return (
    <Layout location={location}>
      <SEO
        title={`Workast for ${team} | Unlock Your Business's Potential with Tailored Solutions`}
        description={heroText}
        image={heroImage}
      />
      <Container>
        <GlobalHero>
          <div className="hero-text">
            <HeroPreHeading>{team}</HeroPreHeading>
            <HeroHeading>{heroTitle}</HeroHeading>
            <BodyTextBig>{heroText}</BodyTextBig>
            <div className="button-wrap">
              <SignUpModal buttonText="Get started" />
            </div>
          </div>
          <div className="hero-img">
            <GatsbyImage image={getImage(heroImage)} alt={heroTitle} />
            <SignUpModal buttonText="Get started" />
          </div>
        </GlobalHero>
      </Container>


      {/* DIVIDER */}
      <DotsDivider />


      {/* FEATURES */}
      {featureItems.map((feature, index) => {
        const showWhiteBackground = (index % 2) === 0;

        return (
          <FeatureItem
            key={feature.title}
            feature={feature}
            showWhiteBackground={showWhiteBackground}
          />
        );
      })}


      {/* DIVIDER */}
      <DotsDivider margin="40px 0 0 0" />


      {/* TEMPLATE ITEMS */}
      {templateItems.length > 0 && (
        <Container>
          <SolutionSection
            items={templateItems}
          />
        </Container>
      )}


      {/* TESTIMONIALS */}
      {reviewItems.length > 0 && (
        <TestimonialSection>
          <SectionTitle mb="20px">
            Testimonials
          </SectionTitle>
          <Testimonials data={reviewItems} />
        </TestimonialSection>
      )}

      {/* CTA */}
      <SimplifyTeamWork />
    </Layout>
  );
};


/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
SolutionPage.defaultProps = {
  data: {},
};
SolutionPage.propTypes = {
  data: PropTypes.shape({
    contentfulSolutionPage: PropTypes.shape({
      team: PropTypes.string,
      heroTitle: PropTypes.string,
      heroText: PropTypes.shape({
        heroText: PropTypes.string,
      }),
      heroImage: PropTypes.shape({
        gatsbyImageData: PropTypes.shape({}),
      }),
      features: PropTypes.arrayOf(
        PropTypes.shape({
          tagline: PropTypes.string,
          title: PropTypes.string,
          description: PropTypes.shape({
            description: PropTypes.string,
          }),
          image: PropTypes.shape({}),
          linkText: PropTypes.string,
          linkUrl: PropTypes.string,
          style: PropTypes.oneOf([
            IMAGE_STYLE.FULL_SCREEN,
            IMAGE_STYLE.LEFT_IMAGE,
            IMAGE_STYLE.RIGHT_IMAGE,
          ]),
        }),
      ),
      reviews: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        }),
      ),
    }),
    allContentfulTemplate: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          tagline: PropTypes.string.isRequired,
          solution: PropTypes.shape({
            team: PropTypes.string,
          }),
          images: PropTypes.arrayOf(
            PropTypes.shape({
              description: PropTypes.string,
              gatsbyImageData: PropTypes.shape({
              }),
            }),
          ),
        })
      ),
    }).isRequired,
  }),
  location: PropTypes.shape({
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
  }).isRequired,
};


/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default SolutionPage;
export const pageQuery = graphql`
  query SolutionBySlug($slug: String!) {
    contentfulSolutionPage(slug: { eq: $slug }) {
      id
      slug
      team
      heroTitle
      heroText {
        heroText
      }
      heroImage {
        gatsbyImageData(width: 1000)
      }
      features {
        tagline
        title
        description {
          description
        }
        linkText
        linkUrl
        image {
          gatsbyImageData(width: 1000)
        }
        style
      }
      reviews {
        id
        description {
          description
        }
        author
        role
        image {
          gatsbyImageData
        }
      }
    }
    allContentfulTemplate(filter: {solution: {slug: {eq: $slug}}}) {
      nodes {
        id
        name
        slug
        tagline
        solution {
          team
        }
        images {
          description
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`;
